import React from 'react';
import { Link, navigate } from 'gatsby';


const RedirectTimer = ({ initTime, redirectUrl, redirectText, props }) => {
    const [timer, setTimer] = React.useState(initTime);
    React.useEffect(() => {
        setTimeout(() => {
            if (timer <= 1) {
                navigate(redirectUrl)
            } else {
                setTimer(timer-1)
            }
        }, 1000)
    }, [timer]);
    return (
        <div {...props}>
            Redirecting to{" "}
            <Link to={redirectUrl}>
            {redirectText}
            </Link>{" "} in <span id="time">{timer}</span> seconds
        </div>
        
    );
}

export default RedirectTimer